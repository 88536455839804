.Pagecontact__title {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}

.Pagecontact__title h1 {
  font-size: 72px;
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 800;
  text-shadow: 2px 2px 7px rgb(145, 145, 145);
}

.Pagecontact {
  width: 100%;
  height: 100vh;
}

.Pagecontact__background {
  width: 100%;
  height: 100vh;
  background: url(../images/contact_bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-attachment: fixed;
  background-size: cover;
}
.Pagecontact__container {
  height: 100%;
  padding: 35px 0;
}

.Pagecontact__container__form--title {
  text-align: center;
}

.Pagecontact__form {
  padding: 0 50px;
}

.text-center {
  text-align: center;
}

.Pagecontact__about {
  margin-bottom: 25px;
}
.map {
  padding-top: 30px;
  border-radius: 5px;
}
.Pagecontact__about iframe {
  width: 100%;
  height: 500px;
}

.Pagecontact__about--logo img {
  width: 100%;
}

.Pagecontact__about-text {
  font-weight: 600;
  margin: 25px 0;
  text-align: justify;
  line-height: 2;
}

.Pagecontact__about--social li {
  list-style: none;
  display: inline-block;
  width: 45px;
  height: 45px;
  border: solid 2px #fa9e1b;
  border-radius: 50%;
  text-align: center;
  transition: all 200ms ease;
  margin-right: 9px;
}

.Pagecontact__about--social li .i {
  display: block;
  position: relative;
  color: #fa9e1b;
  top: 50%;
  left: 6px;
  transform: translateY(-50%);
  font-size: 30px;
  transition: all 200ms ease;
}

.Pagecontact__container .info {
  padding-left: 10px;
  padding-right: 10px;
}

.Pagecontact__container__info {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.Pagecontact__container__info span {
  padding-left: 10px;
  color: var(--black);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  /* text-align: center; */
}
.Pagecontact__container__info > a {
  padding-left: 10px;
  text-align: center;
}
.Pagecontact__container__info span:hover {
  color: var(--yellow);
}

.Pagecontact__container__info--img img {
  max-width: 30px;
  display: block;
  cursor: pointer;
}

.Pagecontact__container__content {
  margin-top: 48px;
}

.img img {
  max-width: 100%;
}

.Pagecontact__container__form {
  position: relative;
  padding: 74px 48px 88px 48px;
  background: var(--border);
  border-radius: 7px;
}

.Pagecontact__container__form h2 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}

.Pagecontact__form input {
  height: 48px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  border-bottom: solid 2px #e1e1e1;
}

.Pagecontact__form textarea {
  height: 126px;
  width: 100%;
  border: none;
  outline: none;
  margin-top: 19px;
  background: transparent;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  border-bottom: solid 2px #e1e1e1;
  padding-top: 11px;
}

.Pagecontact__form input::placeholder,
.Pagecontact__form textarea::placeholder {
  color: var(--white-color);
}

.Pagecontact_form_name,
.Pagecontact_form_email {
  width: calc((100% - 30px) / 2);
}

.Pagecontact_form_name {
  margin-right: 20px;
}

.Pagecontact_form_subject {
  width: 100%;
  margin-top: 12px;
}

.form_submit_button {
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 53px;
  padding: 0 29px;
  background: #31124b;
  transform: translateY(15px);
  border: none;
  outline: none;
  margin-top: 37px;
  cursor: pointer;
  display: inline-block;
  height: 53px;
  border-radius: 27px;
  overflow: hidden;
}

.form_submit_button:hover {
  opacity: 0.8;
}

.Pagecontact__form span {
  display: inline-block;
  vertical-align: middle;
  width: 5.75px;
  height: 5.63px;
  background: #fff;
  border-radius: 50%;
  margin-right: 2px;
  margin-top: -2px;
}

.Pagecontact__form span:first-child {
  margin-left: 10px;
}

.Pagecontact__form span:nth-child(2) {
  opacity: 0.6;
}

.Pagecontact__form span:last-child {
  opacity: 0.4;
}
@media (max-width: 1023px) {
  .Pagecontact__container {
    padding: 50px 10px;
  }
  .Pagecontact__form {
    padding: 0 30px;
  }
  .Pagecontact__about {
    padding-right: 32px;
  }
  .Pagecontact__about--social li {
    width: 32px;
    height: 32px;
  }
  .Pagecontact__about--social li i {
    font-size: 15px;
  }
  .Pagecontact__container__content {
    margin-top: 40px;
  }
}

@media (max-width: 739px) {
  .Pagecontact__form {
    padding: 0 20px;
  }
  .Pagecontact__container {
    padding: 20px 0;
  }
  .padding {
    padding: 0 20px;
  }
  .Pagecontact__about--logo {
    margin-top: 15px;
  }
}

@media (max-width: 479px) {
  .Pagecontact__form {
    padding: 0 10px;
  }
  .Pagecontact__container__form {
    padding-left: 15px;
    padding-right: 15px;
  }
}
