.jobs__title {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
  }
  
  .jobs__title h1 {
    font-size: 72px;
    color: var(--white-color);
    text-transform: uppercase;
    font-weight: 800;
    text-shadow: 5px 2px 5px rgb(141, 141, 141);
  }
  
  .jobs {
  }
  
  .jobs__background {
    width: 100%;
    height: 100vh;
    background: url(../images/tuyendung.jpg);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-attachment: fixed;
    background-size: cover;
  }
  
  .post {
    padding: 70px 0;
  }
 /* src/App.css */

.App {
  text-align: center;
  min-height: 800px;
}

.App-header {
  background-color: #6b626d;
  padding: 20px;
  color: white;
}

.App-content {
  padding: 100px;
}

.job-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}





.job {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.job > p {
  max-height: 60px;
  min-height: 60px;
  overflow: hidden;
  position: relative;
  
}
.job > p::after {
  content: '...';
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 8px;
  background-color: white;
}
.job h2 {
  margin-bottom: 10px;
}

.job button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin: 20px 0;
}

.job button:hover {
  background-color: #45a049;
}

.job-detail {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.job-detail h2 {
  margin-bottom: 10px;
}

.job-detail p {
  margin-bottom: 5px;
}

.job-detail button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.job-detail button:hover {
  background-color: #45a049;
}
/* Thiết lập cho các màn hình nhỏ như điện thoại di động */
@media (max-width: 767px) {
  .jobs__background {
    height: 50vh !important;
  }
  .jobs__title {
    display: block;
    top: 25% !important;
    z-index: -1px;
}
  .jobs__title h1 {
    font-size: 26px !important;
  }
  .App-content {
    padding: 10px;
  }

  .job-list {
    grid-template-columns: 1fr;
  }

  .job-detail {
    padding: 10px;
  }
}

/* Thiết lập cho các màn hình tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .App-content {
    padding: 20px;
  }

  .job-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .job-detail {
    max-width: 500px;
  }
}

/* Thiết lập cho các màn hình lớn hơn */
@media (min-width: 1024px) {
  .App-content {
    padding: 30px;
  }

  .job-list {
    grid-template-columns: repeat(3, 1fr);
  }

  .job-detail {
    max-width: 600px;
  }
}



  @media (max-width: 1239px) {
    .container__sidebar__content--latest {
      margin-bottom: 22px;
    }
    .container__sidebar__content--latest img {
      width: 63px;
      height: 63px;
    }
  }
  
  @media (max-width: 1023px) {
    .container__sidebar__content--latest {
      margin-bottom: 17px;
    }
    .container__sidebar__content--latest img {
      width: 62px;
      height: 62px;
    }
    .blog__container__post {
      padding: 0 15px;
    }
    .blog__container__sidebar {
      padding: 0 10px;
    }
  }
  
  @media (max-width: 739px) {
    .home__title h1 {
      font-size: 60px;
    }
    .top {
      margin-top: 20px;
    }
    .blog__container__post--time {
      width: 75px;
      height: 75px;
      left: 5%;
      top: 4%;
    }
    .blog__container__post--time span:first-child {
      font-size: 26px;
    }
    .blog__container__post--time span:last-child {
      font-size: 11px;
    }
  }
  
  @media (max-width: 479px) {
    .home__title {
      top: 35%;
    }
    .home__title h1 {
      font-size: 42px;
    }
    .blog__container__post--time {
      width: 60px;
      height: 60px;
      left: 7%;
      top: 6%;
    }
    .blog__container__post--time span:first-child {
      font-size: 24px;
    }
    .blog__container__post--time span:last-child {
      font-size: 10px;
    }
  }
  