.tour {
  background-color: #f6f9fb;
  padding: 190px 15px 90px 15px;
}
.tour__image {
  margin-right: 42px;
}
.tour__image img {
  max-width: 100%;
  width: 689px;
  height: 430px;
  object-fit: cover;
}

.tour__content {
  position: relative;
}

.tour__content--title h1 {
  color: var(--black);
  text-transform: uppercase;
  font-size: 30px;
  line-height: 40px;
}

.tour__content--text {
  font-weight: 600;
  color: #929191;
  margin-top: 30px;
  line-height: 2;
  text-align: justify;
  padding-bottom: 35px;
}
.tour_price {
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  color: #eda84a;
  line-height: 25px;
  margin-top: 30px;
}

.tour_price span {
  font-size: 14px;
  font-weight: 400;
  color: #929191;
  margin-left: 5px;
}
.item_rating .item_rating_i {
  font-size: 25px;
}
.tour__infomation {
  width: 100%;
  height: auto;
  padding-bottom: 30px;
  border: 1px solid #999;
  border-radius: 20px;
}
.tour__infomation--title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
  padding: 10px 0;
}
.tour__infomation--form {
  height: 350px;
}
.tour__infomation--input {
  padding: 5px 20px;
  font-size: 20px;
}
.tour__infomation--input > input {
  font-size: 20px;
  padding: 10px;
  width: 100%;
  border: 1px solid #929191;
}
::placeholder {
  color: #929191;
}

.tour__infomation--btn {
  position: absolute;
  bottom: 45px;
  left: 250px;
  transform: translateX(-50%);
  display: inline-block;
  height: 53px;
  border-radius: 27px;
  overflow: hidden;
}

.tour__infomation--btn a {
  display: block;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  line-height: 53px;
  padding-left: 46px;
  padding-right: 46px;
  white-space: nowrap;
  z-index: 2;
}

.tour__infomation--btn span {
  display: inline-block;
  vertical-align: middle;
  width: 5.75px;
  height: 5.63px;
  background: #fff;
  border-radius: 50%;
  margin-right: 2px;
  margin-top: -2px;
}

.tour__infomation--btn span:first-child {
  margin-left: 10px;
}

.tour__infomation--btn span:nth-child(2) {
  opacity: .6;
}

.tour__infomation--btn span:last-child {
  opacity: .4;
  margin-right: 0;
}

.tour__btn__bgc {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(to right, #fa9e1b, #8d4fff, #fa9e1b);
  z-index: -1;
  transition: all 400ms ease;
  display: block;
}

.tour__item--btn:hover .tour__btn__bgc {
  left: -100%;
}
